//NatureBox Global Styles
//font size
html, body {
  font-size: $base-font-size;
}

body {
  @include text-1;
  margin: 0;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  // Disable scrolling when a sidebar is open
  &.frozen {
    overflow-y: hidden;

    // &.ios {
    //   @include fixed-position;
    //   width: 100vw;
    // }
  }
}

//cartpreview & react-section are 1px too low in chrome only
#react-section {
  position: relative;
  top: rem-calc(-1px);

  &.ios-show-nav {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

a,
a:hover,
a:focus {
  color: $primary-color;
  text-decoration: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
}

/**
 * Restore the default cursor to disabled elements unset by the previous rule.
 */
[disabled] {
  cursor: default;
}

// headers
h1 {
  @include h-1;
  &.large {
    @include h1-large;
  }
}

h2 {
  @include h-2;
}

h3 {
  @include h-3;
}

h4 {
  @include h-4;
}

h5 {
  @include h-5;
}

h6 {
  @include h-6;
}

.heading-label {
  @include heading-label;
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

//Override the normalize.css style of placeholders.
::placeholder {
  opacity: 1;
}

//content width
.row {
  max-width: $content-width;
  width: 100%;
  margin: 0 auto;
}

//clearfix
.group::after {
  content: '';
  display: block;
  clear: both;
}

.scrollable {
  -webkit-overflow-scrolling: touch;
}

//forms
.formsy-form {
  .form-group {
    position: relative;
  }

  .form-field-subtext {
    @include h-7;
    margin-top: 4px;
  }

  input:not([type="checkbox"]),
  .stripe-element {
    @include form-input;

    + label {
      @include form-label;
    }

    &.on {
      padding-top: 10px;
    }

    &:not(:focus) {
      + label {
        color: $gray-dark;
      }
    }
  }

  .stripe-element {
    margin-top: .75rem;
    padding-top: rem-calc(12);
  }

  input[type="checkbox"] {
    + label {
      color: $gray-dark;
      padding-left: 0.5rem;
    }
  }

  select {
    @include form-select;
  }
}

.payment-request-button-container {
  max-width: rem-calc(300);
  margin: .75rem auto 0 auto;
}

.formsy-form.stripe, .formsy-form.payment-info-form {
  width: 300px;
  margin: 0 auto;
  button {
    width: 100%;
    margin-top: rem-calc(14);
  }
}

.form-error {
  @include form-error;
}

.active.validation-error {
  color: red;
}

//float label
.float-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;

  label {
    position: absolute;
    text-align: left;
    top: 12px;
    left: $form-input-padding;
    z-index: 10;
    font-size: 11px;
    transition: all 0.2s linear;
    opacity: 0;
    @include primary-font-bold;

    &.on {
      opacity: 1;
      top: 6px;
      color: $body-font-color;
    }
  }

  > input {
    height: rem-calc(48);

    &:focus + label.on {
      color: $primary-color;
    }
  }

  &.column, &.columns {
    label {
      left: calc(#{$form-input-padding} + #{$gutter-size-sm});

      @include breakpoint(lg-up) {
        left: calc(#{$form-input-padding} + #{$gutter-size-lg});
      }
    }
  }
}

//modals
.modal {
  @include modal-base;
}

//modal overlays
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 111;
  @include fixed-position;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;

  h2 {
    margin-top: 0;
  }
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

//cta
.cta {
  @include cta;

  &.inactive, &:disabled {
    @include cta-inactive;
  }
}

.cta-large {
  @include cta-large;

  &.inactive, &:disabled {
    @include cta-inactive;
  }
}

.cta-secondary {
  @include cta-secondary;
}

.cta-secondary-color {
  @include cta-secondary-color;
}

//fonts
.breadcrumbs {
  @include breadcrumbs;
}

.text-1 {
  @include text-1;
}

.text-2 {
  @include text-2;
}

.text-2-bold {
  @include text-2-bold;
}

.text-3 {
  @include text-3;
}

.text-4 {
  @include text-4;
}

.secondary-bold {
  @include secondary-font-bold;
}

.link {
  @include link;
}

.link-small {
  @include link-small;
}

.hide-md-up {
  @include breakpoint(md-up) {
    display: none;
  }
}
.hide-lg-up {
  @include breakpoint(lg-up) {
    display: none;
  }
}
.hide-lg-down {
  @include breakpoint(lg-down) {
    display: none;
  }
}
.hide-lg {
  @include breakpoint(lg) {
    display: none;
  }
}
.hide-md-down {
  @include breakpoint(md-down) {
    display: none;
  }
}

.hide-sm {
  @include breakpoint(sm) {
    display: none;
  }
}

.show-cart-preview {
  display: none;
}

.bold {
  @include primary-font-bold;
}

// word wrap
.word-wrap {
  @include word-wrap;
}

// foundation grid
// columns
.column, .columns {
  padding-left: $gutter-size-sm;
  padding-right: $gutter-size-sm;

  @include breakpoint(lg-up) {
    padding-left: $gutter-size-lg;
    padding-right: $gutter-size-lg;
  }
}

// nested rows
.row .row {
  margin-left: -$gutter-size-sm;
  margin-right: -$gutter-size-sm;

  @include breakpoint(lg-up) {
    margin-left: -$gutter-size-lg;
    margin-right: -$gutter-size-lg;
  }
}

// dropdown arrow
.dropdown-caret {
  &:hover {
    cursor: pointer;
  }
}

.collapsed .dropdown-caret {
  @include svg-icon-carrot-down;
}

.expanded .dropdown-caret {
  @include svg-icon-carrot-up;
}

.accent-1 {
  color: $accent-color-1;
}

.black {
  color: $black;
}

.gray-dark {
  color: $gray-dark;
}

.uppercase {
  text-transform: uppercase;
}

.lock-icon {
  @include svg-icon-lock;
}

.hidden-for-optimizely {
  display: none;
}

.top-border {
  width: 100%;
  height: 6px;
  border-bottom: 6px solid $primary-color;
}

textarea {
  max-width: 100%;
  @include form-placeholder;
}

.loading-spinner {
  margin: -30px auto;
  height: 100%;
  width: 20%;

  svg {
    fill: #fff;
    width: 64px;
    height: 64px;
  }
}
