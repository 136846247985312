footer {
  margin-top: 0;
  background: $white;
  color: $primary-color;
  border-top: 2px solid $gray-700;

  .sailthru-form {
    width: 90%;
    max-width: 1000px;
    margin: auto;
    border-bottom: 1px solid $gray-300;
    padding-bottom: 30px;
    h2,.success-message {
      text-align: center;
    }

    h2 {
      font-weight: normal;
      font-family: $primary-font;
      font-size: 24px;
      text-transform: none;
      color: $primary-color;
    }

    .success-message {
      color: $gray-700;
      font-size: 16px;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      margin: auto;
      max-width: 680px;

      .email-modal-input {
        order: 1;
        width: 65%;
        font-size: 13.3px;
        font-family: $body-font-family;
        color: $gray-700;
        padding-left: 10px;
        background-color: $gray-300;
        border: none;
        border-radius: 3px;
      }
      .form-error {
        color: $error-color;
        order: 3;
        width: 95%;
      }

      .submit-button {
        order: 2;
        width: 30%;
      }
    }

    @include breakpoint(md-down) {
      form {
        flex-direction: column;
        .email-modal-input {
          padding: 15px 10px;
          width: 100%;
        }
        .form-error {
          order: 2;
          width: 100%;
        }
        .submit-button {
          order: 3;
          width: 100%;
          max-width: none;
        }
      }
    }
  }

  .nb-links {
    display: flex;
    padding-left: 40px;
    flex-wrap: wrap;
    @include breakpoint(md-down) {
      row-gap: 20px;
    }
    list-style-type: none;
    padding-top: 16px;
    padding-bottom: 12px;
    max-width: 700px;
    margin: auto;

    a {
      line-height: 1.5rem;
      transition: .2s color ease;
      &:hover, &:focus {
        color: darken($primary-color, 7%);
      }
    }

    li {
      font-size: 16px;
      padding: 0 20px;
      width: 33.3%;
      @include breakpoint(md-down) {
        width: 50%;
      }
    }
  }

  .copyright {
    @include breadcrumb;
    color: $primary-color;
    margin-top: 20px;
    padding-bottom: 10px;
    text-align: center;

    a {
      color: $primary-color;
    }
  }
}
