@charset 'UTF-8';

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  margin-top: -10px\9; /* lte IE 8 */
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover::before,
.slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
  opacity: 1;
}

.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
  opacity: .2;
}

.slick-prev::before,
.slick-next::before {
  font-family: 'slick', sans-serif;
  font-size: 20px;
  line-height: 1;

  opacity: .75;
  color: $white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

.slick-prev::before {
  content: '←';
}

.slick-next {
  right: -25px;
}

.slick-next::before {
  content: '→';
}
