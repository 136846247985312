@mixin icon-sprite-common {
	background: url("../sprites/icon-sprite.view-824975a4.svg") no-repeat;
}

@mixin svg-icon-amazon_logo {
	@include icon-sprite-common;
	background-position: 0 0;
	width: 48px;
	height: 48px;
}

@mixin svg-icon-amazon_logo {
	@include icon-sprite-common;
	background-position: 0.2745995423340961% 0.4242531377054976%;
	width: 48px;
	height: 48px;
}

@mixin svg-icon-amex {
	@include icon-sprite-common;
	background-position: 0.5497022446174988% 0.8479067302596714%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-amex {
	@include icon-sprite-common;
	background-position: 0.9161704076958315% 1.2012012012012012%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-amex-bw {
	@include icon-sprite-common;
	background-position: 1.282638570774164% 1.554495672142731%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-amex-bw {
	@include icon-sprite-common;
	background-position: 1.6491067338524965% 1.9077901430842608%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-apple {
	@include icon-sprite-common;
	background-position: 2.0121184406082087% 2.260286067455412%;
	width: 34px;
	height: 36px;
}

@mixin svg-icon-apple {
	@include icon-sprite-common;
	background-position: 2.2064707899851377% 2.5781387956913298%;
	width: 34px;
	height: 36px;
}

@mixin svg-icon-black-up-arrowhead {
	@include icon-sprite-common;
	background-position: 2.3993144815766922% 2.8896132499339267%;
	width: 23px;
	height: 11px;
}

@mixin svg-icon-black-up-arrowhead {
	@include icon-sprite-common;
	background-position: 2.530705512710654% 2.9865210113646374%;
	width: 23px;
	height: 11px;
}

@mixin svg-icon-blue-triangle-down {
	@include icon-sprite-common;
	background-position: 2.6627049882863836% 3.0842439196334155%;
	width: 27px;
	height: 14px;
}

@mixin svg-icon-blue-triangle-down {
	@include icon-sprite-common;
	background-position: 2.816981886749329% 3.2076136764187524%;
	width: 27px;
	height: 14px;
}

@mixin svg-icon-calendar {
	@include icon-sprite-common;
	background-position: 2.970070824765821% 3.333039414513711%;
	width: 20px;
	height: 21px;
}

@mixin svg-icon-calendar {
	@include icon-sprite-common;
	background-position: 3.0843043180260454% 3.518208270875584%;
	width: 20px;
	height: 21px;
}

@mixin svg-icon-carrot-down {
	@include icon-sprite-common;
	background-position: 3.1974420463629096% 3.699788583509514%;
	width: 14px;
	height: 10px;
}

@mixin svg-icon-carrot-down {
	@include icon-sprite-common;
	background-position: 3.2773780975219826% 3.787878787878788%;
	width: 14px;
	height: 10px;
}

@mixin svg-icon-carrot-down-green {
	@include icon-sprite-common;
	background-position: 3.356547551090307% 3.8746037337090526%;
	width: 10px;
	height: 6px;
}

@mixin svg-icon-carrot-down-green {
	@include icon-sprite-common;
	background-position: 3.4136316931156525% 3.9274392391687214%;
	width: 10px;
	height: 6px;
}

@mixin svg-icon-carrot-down-white {
	@include icon-sprite-common;
	background-position: 3.4715085074797307% 3.9809758675356703%;
	width: 14px;
	height: 8px;
}

@mixin svg-icon-carrot-down-white {
	@include icon-sprite-common;
	background-position: 3.5514445586388033% 4.051435617403558%;
	width: 14px;
	height: 8px;
}

@mixin svg-icon-carrot-left {
	@include icon-sprite-common;
	background-position: 3.6301369863013697% 4.12407472682411%;
	width: 8px;
	height: 14px;
}

@mixin svg-icon-carrot-left {
	@include icon-sprite-common;
	background-position: 3.675799086757991% 4.2474444836094465%;
	width: 8px;
	height: 14px;
}

@mixin svg-icon-carrot-left-dark {
	@include icon-sprite-common;
	background-position: 3.7232463053061973% 4.376213163931533%;
	width: 16.4px;
	height: 28px;
}

@mixin svg-icon-carrot-left-dark {
	@include icon-sprite-common;
	background-position: 3.820324813266635% 4.623257455443797%;
	width: 16.4px;
	height: 28px;
}

@mixin svg-icon-carrot-right {
	@include icon-sprite-common;
	background-position: 3.915525114155251% 4.86429326753613%;
	width: 8px;
	height: 14px;
}

@mixin svg-icon-carrot-right {
	@include icon-sprite-common;
	background-position: 3.961187214611872% 4.987663024321466%;
	width: 8px;
	height: 14px;
}

@mixin svg-icon-carrot-right-dark {
	@include icon-sprite-common;
	background-position: 4.0087713287192495% 5.117346038468326%;
	width: 16.4px;
	height: 28px;
}

@mixin svg-icon-carrot-right-dark {
	@include icon-sprite-common;
	background-position: 4.105849836679687% 5.36439032998059%;
	width: 16.4px;
	height: 28px;
}

@mixin svg-icon-carrot-up {
	@include icon-sprite-common;
	background-position: 4.202352403791252% 5.601550114497093%;
	width: 14px;
	height: 8px;
}

@mixin svg-icon-carrot-up {
	@include icon-sprite-common;
	background-position: 4.282288454950326% 5.6720098643649814%;
	width: 14px;
	height: 8px;
}

@mixin svg-icon-carrot-up-orange {
	@include icon-sprite-common;
	background-position: 4.363789231264836% 5.74651859686233%;
	width: 20.28px;
	height: 16px;
}

@mixin svg-icon-carrot-up-orange {
	@include icon-sprite-common;
	background-position: 4.4837363174645235% 5.887537458135026%;
	width: 20.28px;
	height: 16px;
}

@mixin svg-icon-cart {
	@include icon-sprite-common;
	background-position: 4.605187978516741% 6.031746031746032%;
	width: 26px;
	height: 22px;
}

@mixin svg-icon-cart {
	@include icon-sprite-common;
	background-position: 4.7537424294366355% 6.225749559082892%;
	width: 26px;
	height: 22px;
}

@mixin svg-icon-cart-orange {
	@include icon-sprite-common;
	background-position: 4.900616860863606% 6.419187020544926%;
	width: 20px;
	height: 21px;
}

@mixin svg-icon-cart-orange {
	@include icon-sprite-common;
	background-position: 5.014850354123829% 6.604355876906799%;
	width: 20px;
	height: 21px;
}

@mixin svg-icon-cart-white {
	@include icon-sprite-common;
	background-position: 5.130842189464062% 6.790123456790123%;
	width: 26px;
	height: 22px;
}

@mixin svg-icon-cart-white {
	@include icon-sprite-common;
	background-position: 5.279396640383956% 6.984126984126984%;
	width: 26px;
	height: 22px;
}

@mixin svg-icon-chat-us {
	@include icon-sprite-common;
	background-position: 5.428261242214731% 7.182564193064502%;
	width: 27px;
	height: 29px;
}

@mixin svg-icon-chat-us {
	@include icon-sprite-common;
	background-position: 5.582538140677675% 7.438454072178594%;
	width: 27px;
	height: 29px;
}

@mixin svg-icon-checkmark {
	@include icon-sprite-common;
	background-position: 5.732884143207903% 7.682142542507268%;
	width: 15px;
	height: 11px;
}

@mixin svg-icon-checkmark {
	@include icon-sprite-common;
	background-position: 5.818534802717981% 7.7790503039379795%;
	width: 15px;
	height: 11px;
}

@mixin svg-icon-checkmark-toggle {
	@include icon-sprite-common;
	background-position: 5.90250028542071% 7.8752642706131075%;
	width: 10px;
	height: 10px;
}

@mixin svg-icon-checkmark-toggle {
	@include icon-sprite-common;
	background-position: 5.959584427446056% 7.963354474982382%;
	width: 10px;
	height: 10px;
}

@mixin svg-icon-clock {
	@include icon-sprite-common;
	background-position: 6.0201050948137995% 8.058543466760712%;
	width: 20px;
	height: 20px;
}

@mixin svg-icon-clock {
	@include icon-sprite-common;
	background-position: 6.134338588074023% 8.23487921001587%;
	width: 20px;
	height: 20px;
}

@mixin svg-icon-close {
	@include icon-sprite-common;
	background-position: 6.2471448149840105% 8.408249603384453%;
	width: 16px;
	height: 16px;
}

@mixin svg-icon-close {
	@include icon-sprite-common;
	background-position: 6.33851073549566% 8.549268464657148%;
	width: 16px;
	height: 16px;
}

@mixin svg-icon-compass {
	@include icon-sprite-common;
	background-position: 6.436860458469102% 8.704864483093493%;
	width: 35px;
	height: 35px;
}

@mixin svg-icon-compass {
	@include icon-sprite-common;
	background-position: 6.636940490481907% 9.013860686854418%;
	width: 35px;
	height: 35px;
}

@mixin svg-icon-corporate-logos {
	@include icon-sprite-common;
	background-position: 6.88622754491018% 9.796827163929864%;
	width: 160px;
	height: 583px;
}

@mixin svg-icon-corporate-logos {
	@include icon-sprite-common;
	background-position: 7.8074619990787655% 15.205492160682809%;
	width: 160px;
	height: 583px;
}

@mixin svg-icon-dinersclub {
	@include icon-sprite-common;
	background-position: 8.680714612918003% 19.625507860801978%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-dinersclub {
	@include icon-sprite-common;
	background-position: 9.047182775996335% 19.97880233174351%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-dinersclub-bw {
	@include icon-sprite-common;
	background-position: 9.413650939074667% 20.332096802685037%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-dinersclub-bw {
	@include icon-sprite-common;
	background-position: 9.780119102153% 20.685391273626568%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-discover {
	@include icon-sprite-common;
	background-position: 10.146587265231332% 21.0386857445681%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-discover {
	@include icon-sprite-common;
	background-position: 10.513055428309666% 21.391980215509626%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-discover-bw {
	@include icon-sprite-common;
	background-position: 10.879523591387999% 21.745274686451157%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-discover-bw {
	@include icon-sprite-common;
	background-position: 11.245991754466331% 22.09856915739269%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-email-us {
	@include icon-sprite-common;
	background-position: 11.590558381436818% 22.418202663374196%;
	width: 31px;
	height: 23px;
}

@mixin svg-icon-email-us {
	@include icon-sprite-common;
	background-position: 11.767731611133337% 22.621042419966486%;
	width: 31px;
	height: 23px;
}

@mixin svg-icon-facebook {
	@include icon-sprite-common;
	background-position: 11.937400045693398% 22.81784517721742%;
	width: 20px;
	height: 20px;
}

@mixin svg-icon-facebook {
	@include icon-sprite-common;
	background-position: 12.051633538953622% 22.99418092047258%;
	width: 20px;
	height: 20px;
}

@mixin svg-icon-facebook2 {
	@include icon-sprite-common;
	background-position: 12.165867032213844% 23.17051666372774%;
	width: 20px;
	height: 20px;
}

@mixin svg-icon-facebook2 {
	@include icon-sprite-common;
	background-position: 12.280100525474069% 23.346852406982894%;
	width: 20px;
	height: 20px;
}

@mixin svg-icon-fb-logo {
	@include icon-sprite-common;
	background-position: 12.392918332381496% 23.51904090267983%;
	width: 18px;
	height: 18px;
}

@mixin svg-icon-fb-logo {
	@include icon-sprite-common;
	background-position: 12.495716733295259% 23.67771509167842%;
	width: 18px;
	height: 18px;
}

@mixin svg-icon-free-shipping {
	@include icon-sprite-common;
	background-position: 12.643282897753323% 23.96738166991668%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-free-shipping {
	@include icon-sprite-common;
	background-position: 13.101788170563962% 24.676475802162738%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-generic-credit-card {
	@include icon-sprite-common;
	background-position: 13.51691042047532% 25.242376167812445%;
	width: 24px;
	height: 16px;
}

@mixin svg-icon-generic-credit-card {
	@include icon-sprite-common;
	background-position: 13.65402193784278% 25.38339502908514%;
	width: 24px;
	height: 16px;
}

@mixin svg-icon-gift {
	@include icon-sprite-common;
	background-position: 13.785620467134944% 25.527878806042775%;
	width: 17px;
	height: 17.54px;
}

@mixin svg-icon-gift {
	@include icon-sprite-common;
	background-position: 13.882702301410541% 25.686546561052708%;
	width: 17px;
	height: 17.54px;
}

@mixin svg-icon-gift-black {
	@include icon-sprite-common;
	background-position: 13.97978413568614% 25.845214316062645%;
	width: 17px;
	height: 17.54px;
}

@mixin svg-icon-gift-black {
	@include icon-sprite-common;
	background-position: 14.076865969961739% 26.003882071072578%;
	width: 17px;
	height: 17.54px;
}

@mixin svg-icon-gift-white {
	@include icon-sprite-common;
	background-position: 14.173947804237336% 26.16254982608251%;
	width: 17px;
	height: 17.54px;
}

@mixin svg-icon-gift-white {
	@include icon-sprite-common;
	background-position: 14.271029638512935% 26.321217581092448%;
	width: 17px;
	height: 17.54px;
}

@mixin svg-icon-gray-arrow-down {
	@include icon-sprite-common;
	background-position: 14.38783096014182% 26.487964024336478%;
	width: 41px;
	height: 21px;
}

@mixin svg-icon-gray-arrow-down {
	@include icon-sprite-common;
	background-position: 14.622290844627438% 26.67313288069835%;
	width: 41px;
	height: 21px;
}

@mixin svg-icon-hamburger {
	@include icon-sprite-common;
	background-position: 14.842321755027422% 26.86067019400353%;
	width: 24px;
	height: 22px;
}

@mixin svg-icon-hamburger {
	@include icon-sprite-common;
	background-position: 14.979433272394882% 27.05467372134039%;
	width: 24px;
	height: 22px;
}

@mixin svg-icon-heart-orange {
	@include icon-sprite-common;
	background-position: 15.114817776762253% 27.24867724867725%;
	width: 22px;
	height: 22px;
}

@mixin svg-icon-heart-orange {
	@include icon-sprite-common;
	background-position: 15.2404889752085% 27.44268077601411%;
	width: 22px;
	height: 22px;
}

@mixin svg-icon-hiw-manager {
	@include icon-sprite-common;
	background-position: 15.41723979825768% 27.77876263073923%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-hiw-manager {
	@include icon-sprite-common;
	background-position: 15.875745071068318% 28.487856762985285%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-hiw-month {
	@include icon-sprite-common;
	background-position: 16.334250343878956% 29.196950895231343%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-hiw-month {
	@include icon-sprite-common;
	background-position: 16.792755616689593% 29.906045027477397%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-hiw-shipping {
	@include icon-sprite-common;
	background-position: 17.25126088950023% 30.615139159723455%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-hiw-shipping {
	@include icon-sprite-common;
	background-position: 17.709766162310867% 31.32423329196951%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-hiw-variety {
	@include icon-sprite-common;
	background-position: 18.168271435121504% 32.033327424215564%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-hiw-variety {
	@include icon-sprite-common;
	background-position: 18.62677670793214% 32.74242155646162%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-individual-snack-icon {
	@include icon-sprite-common;
	background-position: 19.075442515896203% 33.46634743282788%;
	width: 71px;
	height: 85px;
}

@mixin svg-icon-individual-snack-icon {
	@include icon-sprite-common;
	background-position: 19.48215615512402% 34.22009399663031%;
	width: 71px;
	height: 85px;
}

@mixin svg-icon-info {
	@include icon-sprite-common;
	background-position: 19.828669331810396% 34.7672778561354%;
	width: 18px;
	height: 18px;
}

@mixin svg-icon-info {
	@include icon-sprite-common;
	background-position: 19.93146773272416% 34.92595204513399%;
	width: 18px;
	height: 18px;
}

@mixin svg-icon-info-black {
	@include icon-sprite-common;
	background-position: 20.03655471784327% 35.0908129077764%;
	width: 20px;
	height: 20px;
}

@mixin svg-icon-info-black {
	@include icon-sprite-common;
	background-position: 20.150788211103496% 35.26714865103156%;
	width: 20px;
	height: 20px;
}

@mixin svg-icon-instagram-white {
	@include icon-sprite-common;
	background-position: 20.262707024557397% 35.43723554301834%;
	width: 18px;
	height: 18px;
}

@mixin svg-icon-instagram-white {
	@include icon-sprite-common;
	background-position: 20.36550542547116% 35.59590973201693%;
	width: 18px;
	height: 18px;
}

@mixin svg-icon-instagram2 {
	@include icon-sprite-common;
	background-position: 20.468303826384922% 35.75458392101552%;
	width: 18px;
	height: 18px;
}

@mixin svg-icon-instagram2 {
	@include icon-sprite-common;
	background-position: 20.571102227298688% 35.913258110014105%;
	width: 18px;
	height: 18px;
}

@mixin svg-icon-jcb {
	@include icon-sprite-common;
	background-position: 20.728355474118185% 36.14202437731849%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-jcb {
	@include icon-sprite-common;
	background-position: 21.09482363719652% 36.49531884826003%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-jcb-bw {
	@include icon-sprite-common;
	background-position: 21.46129180027485% 36.848613319201554%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-jcb-bw {
	@include icon-sprite-common;
	background-position: 21.827759963353184% 37.20190779014308%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-lightbox-carrot-left {
	@include icon-sprite-common;
	background-position: 22.15110298319808% 37.60169791298196%;
	width: 30px;
	height: 54px;
}

@mixin svg-icon-lightbox-carrot-left {
	@include icon-sprite-common;
	background-position: 22.322551148702708% 38.07923593915812%;
	width: 30px;
	height: 54px;
}

@mixin svg-icon-lightbox-carrot-right {
	@include icon-sprite-common;
	background-position: 22.493999314207336% 38.55677396533428%;
	width: 30px;
	height: 54px;
}

@mixin svg-icon-lightbox-carrot-right {
	@include icon-sprite-common;
	background-position: 22.66544747971197% 39.034311991510435%;
	width: 30px;
	height: 54px;
}

@mixin svg-icon-linkedin-white {
	@include icon-sprite-common;
	background-position: 22.821245002855512% 39.38993211672397%;
	width: 18px;
	height: 19px;
}

@mixin svg-icon-linkedin-white {
	@include icon-sprite-common;
	background-position: 22.924043403769275% 39.55743630432866%;
	width: 18px;
	height: 19px;
}

@mixin svg-icon-linkedin2 {
	@include icon-sprite-common;
	background-position: 23.026841804683038% 39.72494049193335%;
	width: 18px;
	height: 19px;
}

@mixin svg-icon-linkedin2 {
	@include icon-sprite-common;
	background-position: 23.1296402055968% 39.89244467953804%;
	width: 18px;
	height: 19px;
}

@mixin svg-icon-lock {
	@include icon-sprite-common;
	background-position: 23.225806451612904% 40.0422982023264%;
	width: 13px;
	height: 14px;
}

@mixin svg-icon-lock {
	@include icon-sprite-common;
	background-position: 23.300028546959748% 40.16566795911174%;
	width: 13px;
	height: 14px;
}

@mixin svg-icon-logo_holiday_orange {
	@include icon-sprite-common;
	background-position: 25.039755351681958% 40.58912829254002%;
	width: 1178px;
	height: 97.9px;
}

@mixin svg-icon-logo_holiday_orange {
	@include icon-sprite-common;
	background-position: 32.24464831804281% 41.4591489777257%;
	width: 1178px;
	height: 97.9px;
}

@mixin svg-icon-logo_holiday_white {
	@include icon-sprite-common;
	background-position: 39.44954128440367% 42.32916966291137%;
	width: 1178px;
	height: 97.9px;
}

@mixin svg-icon-logo_holiday_white {
	@include icon-sprite-common;
	background-position: 46.654434250764524% 43.19919034809705%;
	width: 1178px;
	height: 97.9px;
}

@mixin svg-icon-map-pin {
	@include icon-sprite-common;
	background-position: 50.2855185015989% 43.77425044091711%;
	width: 16px;
	height: 22px;
}

@mixin svg-icon-map-pin {
	@include icon-sprite-common;
	background-position: 50.37688442211055% 43.96825396825397%;
	width: 16px;
	height: 22px;
}

@mixin svg-icon-mastercard {
	@include icon-sprite-common;
	background-position: 50.60696289509849% 44.232467761879526%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-mastercard {
	@include icon-sprite-common;
	background-position: 50.97343105817682% 44.585762232821054%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-mastercard-bw {
	@include icon-sprite-common;
	background-position: 51.33989922125515% 44.93905670376259%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-mastercard-bw {
	@include icon-sprite-common;
	background-position: 51.70636738433348% 45.292351174704116%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-membership-logo {
	@include icon-sprite-common;
	background-position: 52.93980672953778% 45.59731780483501%;
	width: 350px;
	height: 28px;
}

@mixin svg-icon-membership-logo {
	@include icon-sprite-common;
	background-position: 54.977296542088716% 45.844362096347275%;
	width: 350px;
	height: 28px;
}

@mixin svg-icon-membership-logo-new {
	@include icon-sprite-common;
	background-position: 57.014786354639654% 46.091406387859536%;
	width: 350px;
	height: 28px;
}

@mixin svg-icon-membership-logo-new {
	@include icon-sprite-common;
	background-position: 59.05227616719059% 46.3384506793718%;
	width: 350px;
	height: 28px;
}

@mixin svg-icon-membership-logo-new-white {
	@include icon-sprite-common;
	background-position: 61.08976597974153% 46.58549497088406%;
	width: 350px;
	height: 28px;
}

@mixin svg-icon-membership-logo-new-white {
	@include icon-sprite-common;
	background-position: 63.127255792292466% 46.83253926239633%;
	width: 350px;
	height: 28px;
}

@mixin svg-icon-new-snacks {
	@include icon-sprite-common;
	background-position: 64.15635029802843% 47.296578620811914%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-new-snacks {
	@include icon-sprite-common;
	background-position: 64.61485557083907% 48.005672753057965%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-no {
	@include icon-sprite-common;
	background-position: 64.82813749000799% 48.431441663729295%;
	width: 14px;
	height: 14px;
}

@mixin svg-icon-no {
	@include icon-sprite-common;
	background-position: 64.90807354116707% 48.554811420514625%;
	width: 14px;
	height: 14px;
}

@mixin svg-icon-office {
	@include icon-sprite-common;
	background-position: 64.99914339557992% 48.69534555712271%;
	width: 17px;
	height: 18px;
}

@mixin svg-icon-office {
	@include icon-sprite-common;
	background-position: 65.09622522985552% 48.854019746121295%;
	width: 17px;
	height: 18px;
}

@mixin svg-icon-orange-cash {
	@include icon-sprite-common;
	background-position: 65.24547065211179% 49.01701489905669%;
	width: 31px;
	height: 19px;
}

@mixin svg-icon-orange-cash {
	@include icon-sprite-common;
	background-position: 65.42264388180831% 49.184519086661375%;
	width: 31px;
	height: 19px;
}

@mixin svg-icon-orange-checkmark {
	@include icon-sprite-common;
	background-position: 65.53988465711186% 49.31724077173817%;
	width: 15px;
	height: 11px;
}

@mixin svg-icon-orange-checkmark {
	@include icon-sprite-common;
	background-position: 65.62553531662194% 49.414148533168884%;
	width: 15px;
	height: 11px;
}

@mixin svg-icon-orange-free-membership {
	@include icon-sprite-common;
	background-position: 65.72619795533726% 49.580943978826646%;
	width: 19px;
	height: 27px;
}

@mixin svg-icon-orange-free-membership {
	@include icon-sprite-common;
	background-position: 65.83471357587527% 49.819144243493604%;
	width: 19px;
	height: 27px;
}

@mixin svg-icon-orange-truck {
	@include icon-sprite-common;
	background-position: 65.96206581352834% 50.017630465444284%;
	width: 24px;
	height: 18px;
}

@mixin svg-icon-orange-truck {
	@include icon-sprite-common;
	background-position: 66.0991773308958% 50.17630465444288%;
	width: 24px;
	height: 18px;
}

@mixin svg-icon-pinterest {
	@include icon-sprite-common;
	background-position: 66.22115604295179% 50.3482938012521%;
	width: 20px;
	height: 21px;
}

@mixin svg-icon-pinterest {
	@include icon-sprite-common;
	background-position: 66.33538953621202% 50.53346265761397%;
	width: 20px;
	height: 21px;
}

@mixin svg-icon-plus {
	@include icon-sprite-common;
	background-position: 66.43444495203289% 50.69628062753393%;
	width: 16px;
	height: 16px;
}

@mixin svg-icon-plus {
	@include icon-sprite-common;
	background-position: 66.52581087254454% 50.83729948880663%;
	width: 16px;
	height: 16px;
}

@mixin svg-icon-plus-orange {
	@include icon-sprite-common;
	background-position: 66.64031367674478% 51.00565083351558%;
	width: 22.08px;
	height: 22.08px;
}

@mixin svg-icon-plus-orange {
	@include icon-sprite-common;
	background-position: 66.77169780280043% 51.20847413385632%;
	width: 22.08px;
	height: 22.08px;
}

@mixin svg-icon-plus-white {
	@include icon-sprite-common;
	background-position: 66.87985381452718% 51.38374757623832%;
	width: 16px;
	height: 16px;
}

@mixin svg-icon-plus-white {
	@include icon-sprite-common;
	background-position: 66.97121973503883% 51.52476643751102%;
	width: 16px;
	height: 16px;
}

@mixin svg-icon-presell-arrow-left {
	@include icon-sprite-common;
	background-position: 67.30471660267064% 52.09740490579453%;
	width: 79px;
	height: 110px;
}

@mixin svg-icon-presell-arrow-left {
	@include icon-sprite-common;
	background-position: 67.7574646111525% 53.075008887308925%;
	width: 79px;
	height: 110px;
}

@mixin svg-icon-presell-arrow-right {
	@include icon-sprite-common;
	background-position: 68.21021261963436% 54.05261286882332%;
	width: 79px;
	height: 110px;
}

@mixin svg-icon-presell-arrow-right {
	@include icon-sprite-common;
	background-position: 68.66296062811622% 55.030216850337716%;
	width: 79px;
	height: 110px;
}

@mixin svg-icon-presell-close {
	@include icon-sprite-common;
	background-position: 68.93398113746785% 55.62715155794863%;
	width: 33px;
	height: 33px;
}

@mixin svg-icon-presell-close {
	@include icon-sprite-common;
	background-position: 69.12260645898829% 55.918439403301264%;
	width: 33px;
	height: 33px;
}

@mixin svg-icon-presell-info {
	@include icon-sprite-common;
	background-position: 69.31123178050872% 56.2097272486539%;
	width: 33px;
	height: 33px;
}

@mixin svg-icon-presell-info {
	@include icon-sprite-common;
	background-position: 69.49985710202915% 56.50101509400653%;
	width: 33px;
	height: 33px;
}

@mixin svg-icon-question-mark {
	@include icon-sprite-common;
	background-position: 69.63673749143248% 56.74221712673075%;
	width: 20px;
	height: 23px;
}

@mixin svg-icon-question-mark {
	@include icon-sprite-common;
	background-position: 69.75097098469271% 56.94505688332305%;
	width: 20px;
	height: 23px;
}

@mixin svg-icon-quotes {
	@include icon-sprite-common;
	background-position: 69.87318633611333% 57.122708039492245%;
	width: 22px;
	height: 18px;
}

@mixin svg-icon-quotes {
	@include icon-sprite-common;
	background-position: 69.99885753455958% 57.281382228490834%;
	width: 22px;
	height: 18px;
}

@mixin svg-icon-search-icon {
	@include icon-sprite-common;
	background-position: 70.12052321928371% 57.46031746031746%;
	width: 21px;
	height: 22px;
}

@mixin svg-icon-search-icon {
	@include icon-sprite-common;
	background-position: 70.24047523847604% 57.65432098765432%;
	width: 21px;
	height: 22px;
}

@mixin svg-icon-smalltruck {
	@include icon-sprite-common;
	background-position: 70.46910755148741% 57.868736767819335%;
	width: 48px;
	height: 26px;
}

@mixin svg-icon-smalltruck {
	@include icon-sprite-common;
	background-position: 70.74370709382151% 58.098094565984475%;
	width: 48px;
	height: 26px;
}

@mixin svg-icon-smart-snack-guarantee {
	@include icon-sprite-common;
	background-position: 71.14855570839065% 58.6066300301365%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-smart-snack-guarantee {
	@include icon-sprite-common;
	background-position: 71.60706098120129% 59.31572416238256%;
	width: 80px;
	height: 80px;
}

@mixin svg-icon-sms {
	@include icon-sprite-common;
	background-position: 71.89251000571755% 59.75997176138369%;
	width: 38px;
	height: 30px;
}

@mixin svg-icon-sms {
	@include icon-sprite-common;
	background-position: 72.10977701543739% 60.024708789269326%;
	width: 38px;
	height: 30px;
}

@mixin svg-icon-snack-value-calcium {
	@include icon-sprite-common;
	background-position: 72.41813602015114% 60.44947796850115%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-calcium {
	@include icon-sprite-common;
	background-position: 72.76162125028624% 60.98035745885684%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-fat-free {
	@include icon-sprite-common;
	background-position: 73.10510648042134% 61.51123694921253%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-fat-free {
	@include icon-sprite-common;
	background-position: 73.44859171055644% 62.04211643956822%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-fiber {
	@include icon-sprite-common;
	background-position: 73.79207694069154% 62.572995929923906%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-fiber {
	@include icon-sprite-common;
	background-position: 74.13556217082666% 63.1038754202796%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-gluten-free {
	@include icon-sprite-common;
	background-position: 74.47904740096176% 63.63475491063529%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-gluten-free {
	@include icon-sprite-common;
	background-position: 74.82253263109686% 64.16563440099098%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-iron {
	@include icon-sprite-common;
	background-position: 75.16601786123196% 64.69651389134667%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-iron {
	@include icon-sprite-common;
	background-position: 75.50950309136707% 65.22739338170236%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-keto {
	@include icon-sprite-common;
	background-position: 75.85298832150218% 65.75827287205804%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-keto {
	@include icon-sprite-common;
	background-position: 76.19647355163728% 66.28915236241373%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-low-cal {
	@include icon-sprite-common;
	background-position: 76.53995878177238% 66.82003185276942%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-low-cal {
	@include icon-sprite-common;
	background-position: 76.88344401190749% 67.3509113431251%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-low-carb {
	@include icon-sprite-common;
	background-position: 77.22692924204259% 67.8817908334808%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-low-carb {
	@include icon-sprite-common;
	background-position: 77.5704144721777% 68.4126703238365%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-low-cholesterol {
	@include icon-sprite-common;
	background-position: 77.9138997023128% 68.94354981419218%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-low-cholesterol {
	@include icon-sprite-common;
	background-position: 78.2573849324479% 69.47442930454787%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-low-fat {
	@include icon-sprite-common;
	background-position: 78.600870162583% 70.00530879490356%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-low-fat {
	@include icon-sprite-common;
	background-position: 78.94435539271811% 70.53618828525924%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-low-sodium {
	@include icon-sprite-common;
	background-position: 79.28784062285322% 71.06706777561493%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-low-sodium {
	@include icon-sprite-common;
	background-position: 79.63132585298833% 71.59794726597063%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-low-sugar {
	@include icon-sprite-common;
	background-position: 79.97481108312343% 72.12882675632632%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-low-sugar {
	@include icon-sprite-common;
	background-position: 80.31829631325853% 72.659706246682%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-made-without-gluten {
	@include icon-sprite-common;
	background-position: 80.66178154339363% 73.1905857370377%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-made-without-gluten {
	@include icon-sprite-common;
	background-position: 81.00526677352873% 73.72146522739338%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-no-sugar {
	@include icon-sprite-common;
	background-position: 81.34875200366385% 74.25234471774907%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-no-sugar {
	@include icon-sprite-common;
	background-position: 81.69223723379895% 74.78322420810476%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-non-gmo {
	@include icon-sprite-common;
	background-position: 82.03572246393405% 75.31410369846044%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-non-gmo {
	@include icon-sprite-common;
	background-position: 82.37920769406915% 75.84498318881614%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-paleo {
	@include icon-sprite-common;
	background-position: 82.72269292420425% 76.37586267917183%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-paleo {
	@include icon-sprite-common;
	background-position: 83.06617815433937% 76.90674216952752%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-potassium {
	@include icon-sprite-common;
	background-position: 83.40966338447447% 77.4376216598832%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-potassium {
	@include icon-sprite-common;
	background-position: 83.75314861460957% 77.9685011502389%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-protein {
	@include icon-sprite-common;
	background-position: 84.09663384474467% 78.49938064059458%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-protein {
	@include icon-sprite-common;
	background-position: 84.44011907487977% 79.03026013095027%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-real-cheese {
	@include icon-sprite-common;
	background-position: 84.78360430501489% 79.56113962130597%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-real-cheese {
	@include icon-sprite-common;
	background-position: 85.12708953514999% 80.09201911166166%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-real-honey {
	@include icon-sprite-common;
	background-position: 85.4705747652851% 80.62289860201734%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-real-honey {
	@include icon-sprite-common;
	background-position: 85.8140599954202% 81.15377809237303%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-vegan {
	@include icon-sprite-common;
	background-position: 86.1575452255553% 81.68465758272872%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-vegan {
	@include icon-sprite-common;
	background-position: 86.50103045569041% 82.2155370730844%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-vegetarian {
	@include icon-sprite-common;
	background-position: 86.84451568582551% 82.74641656344009%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-vegetarian {
	@include icon-sprite-common;
	background-position: 87.18800091596061% 83.2772960537958%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-vitamin-a {
	@include icon-sprite-common;
	background-position: 87.53148614609572% 83.80817554415148%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-vitamin-a {
	@include icon-sprite-common;
	background-position: 87.87497137623082% 84.33905503450717%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-vitamin-c {
	@include icon-sprite-common;
	background-position: 88.21845660636593% 84.86993452486286%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-snack-value-vitamin-c {
	@include icon-sprite-common;
	background-position: 88.56194183650103% 85.40081401521854%;
	width: 60px;
	height: 60px;
}

@mixin svg-icon-truck {
	@include icon-sprite-common;
	background-position: 88.71244144864617% 85.605993829881%;
	width: 22px;
	height: 17px;
}

@mixin svg-icon-truck {
	@include icon-sprite-common;
	background-position: 88.83811264709243% 85.75583957690613%;
	width: 22px;
	height: 17px;
}

@mixin svg-icon-truck-orange {
	@include icon-sprite-common;
	background-position: 88.95870223339236% 85.89811387273048%;
	width: 21px;
	height: 16px;
}

@mixin svg-icon-truck-orange {
	@include icon-sprite-common;
	background-position: 89.07865425258468% 86.03913273400318%;
	width: 21px;
	height: 16px;
}

@mixin svg-icon-twitter {
	@include icon-sprite-common;
	background-position: 89.19351153758282% 86.18774790656677%;
	width: 20px;
	height: 17px;
}

@mixin svg-icon-twitter {
	@include icon-sprite-common;
	background-position: 89.30774503084304% 86.3375936535919%;
	width: 20px;
	height: 17px;
}

@mixin svg-icon-twitter-white {
	@include icon-sprite-common;
	background-position: 89.41176470588235% 86.47219529391029%;
	width: 18px;
	height: 15px;
}

@mixin svg-icon-twitter-white {
	@include icon-sprite-common;
	background-position: 89.51456310679612% 86.60438882524015%;
	width: 18px;
	height: 15px;
}

@mixin svg-icon-twitter2 {
	@include icon-sprite-common;
	background-position: 89.61736150770987% 86.73658235657003%;
	width: 18px;
	height: 15px;
}

@mixin svg-icon-twitter2 {
	@include icon-sprite-common;
	background-position: 89.72015990862364% 86.86877588789989%;
	width: 18px;
	height: 15px;
}

@mixin svg-icon-unique {
	@include icon-sprite-common;
	background-position: 89.9051103235395% 87.14689265536722%;
	width: 34px;
	height: 34px;
}

@mixin svg-icon-unique {
	@include icon-sprite-common;
	background-position: 90.09946267291643% 87.44703389830508%;
	width: 34px;
	height: 34px;
}

@mixin svg-icon-variety {
	@include icon-sprite-common;
	background-position: 90.34029167858164% 87.77041942604856%;
	width: 43px;
	height: 37px;
}

@mixin svg-icon-variety {
	@include icon-sprite-common;
	background-position: 90.58621675722047% 88.09713024282561%;
	width: 43px;
	height: 37px;
}

@mixin svg-icon-vb-arrow {
	@include icon-sprite-common;
	background-position: 90.74910005142563% 88.24462460345435%;
	width: 27px;
	height: 14px;
}

@mixin svg-icon-vb-arrow {
	@include icon-sprite-common;
	background-position: 90.90337694988858% 88.36799436023969%;
	width: 27px;
	height: 14px;
}

@mixin svg-icon-visa {
	@include icon-sprite-common;
	background-position: 91.2505726065048% 88.69457692987105%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-visa {
	@include icon-sprite-common;
	background-position: 91.61704076958314% 89.04787140081258%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-visa-bw {
	@include icon-sprite-common;
	background-position: 91.98350893266148% 89.4011658717541%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-visa-bw {
	@include icon-sprite-common;
	background-position: 92.3499770957398% 89.75446034269564%;
	width: 64px;
	height: 40px;
}

@mixin svg-icon-voicemail {
	@include icon-sprite-common;
	background-position: 92.54157855632394% 90.0441306266549%;
	width: 31px;
	height: 32px;
}

@mixin svg-icon-voicemail {
	@include icon-sprite-common;
	background-position: 92.71875178602046% 90.32656663724624%;
	width: 31px;
	height: 32px;
}

@mixin svg-icon-website {
	@include icon-sprite-common;
	background-position: 92.83755997258396% 90.52910052910053%;
	width: 20px;
	height: 22px;
}

@mixin svg-icon-website {
	@include icon-sprite-common;
	background-position: 92.95179346584419% 90.72310405643739%;
	width: 20px;
	height: 22px;
}

@mixin svg-icon-winb-free-membership {
	@include icon-sprite-common;
	background-position: 93.241773962804% 91.16632770359891%;
	width: 53px;
	height: 53px;
}

@mixin svg-icon-winb-free-membership {
	@include icon-sprite-common;
	background-position: 93.54506437768241% 91.63498098859316%;
	width: 53px;
	height: 53px;
}

@mixin svg-icon-winb-free-shipping {
	@include icon-sprite-common;
	background-position: 94.07445649056388% 92.06293088209299%;
	width: 95px;
	height: 48px;
}

@mixin svg-icon-winb-free-shipping {
	@include icon-sprite-common;
	background-position: 94.6193999885275% 92.48718401979848%;
	width: 95px;
	height: 48px;
}

@mixin svg-icon-winb-free-shipping-transparent {
	@include icon-sprite-common;
	background-position: 95.16434348649113% 92.91143715750398%;
	width: 95px;
	height: 48px;
}

@mixin svg-icon-winb-free-shipping-transparent {
	@include icon-sprite-common;
	background-position: 95.70928698445476% 93.33569029520947%;
	width: 95px;
	height: 48px;
}

@mixin svg-icon-winb-real-ingredients {
	@include icon-sprite-common;
	background-position: 96.11639363042731% 93.90103567318758%;
	width: 70px;
	height: 65px;
}

@mixin svg-icon-winb-real-ingredients {
	@include icon-sprite-common;
	background-position: 96.51735593997022% 94.47640966628309%;
	width: 70px;
	height: 65px;
}

@mixin svg-icon-winb-we-will-credit-you {
	@include icon-sprite-common;
	background-position: 96.79633867276888% 95.04337050805452%;
	width: 48px;
	height: 64px;
}

@mixin svg-icon-winb-we-will-credit-you {
	@include icon-sprite-common;
	background-position: 97.07093821510297% 95.60984244999115%;
	width: 48px;
	height: 64px;
}

@mixin svg-icon-wnb-endless-options {
	@include icon-sprite-common;
	background-position: 97.52407152682255% 96.24446412754651%;
	width: 80px;
	height: 72px;
}

@mixin svg-icon-wnb-endless-options {
	@include icon-sprite-common;
	background-position: 97.9825767996332% 96.88219663418955%;
	width: 80px;
	height: 72px;
}

@mixin svg-icon-wnb-flavor-quality {
	@include icon-sprite-common;
	background-position: 98.39596700274977% 97.5199291408326%;
	width: 72px;
	height: 72px;
}

@mixin svg-icon-wnb-flavor-quality {
	@include icon-sprite-common;
	background-position: 98.80843263061412% 98.15766164747565%;
	width: 72px;
	height: 72px;
}

@mixin svg-icon-wnb-save-a-bundle {
	@include icon-sprite-common;
	background-position: 99.22089825847846% 98.79539415411868%;
	width: 72px;
	height: 72px;
}

@mixin svg-icon-wnb-save-a-bundle {
	@include icon-sprite-common;
	background-position: 99.6333638863428% 99.43312666076173%;
	width: 72px;
	height: 72px;
}

@mixin svg-icon-youtube {
	@include icon-sprite-common;
	background-position: 99.81710105166896% 99.71756398940865%;
	width: 32px;
	height: 32px;
}

@mixin svg-icon-youtube {
	@include icon-sprite-common;
	background-position: 100% 100%;
	width: 32px;
	height: 32px;
}

