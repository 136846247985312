//css reset
@import "normalize.css";

//foundation settings
@import "mixins/foundation_functions";
@import "settings/foundation_settings";
@import "partials/foundation_global";
@import "partials/foundation_grid";

//sass variables
@import "settings/naturebox_settings";

//mixins
@import "mixins/media_club";
@import "mixins/fonts";
@import "mixins/button";
@import "mixins/icon";
@import "mixins/content-padding";
@import "mixins/banner-image";
@import "mixins/snack-font-sizes";
@import "mixins/snack_name_ellipsis";
@import "mixins/input";
@import "mixins/form";
@import "mixins/modal";
@import "mixins/cta";
@import "mixins/line";
@import "mixins/vertical-align";
@import "mixins/tooltip";
@import "mixins/sprites";
@import "mixins/transitions";
@import "mixins/slider";
@import "mixins/fixes";
@import "mixins/checkout_success";
@import "mixins/member-banner-image";

@import "../../assets/scss/icon-sprite";

//slider
@import "vendor/slick";
@import "vendor/slick-theme";

//naturebox global styles
@import "partials/webfonts";
@import "partials/global";

@import "partials/extend_foundation_grid";
@import "partials/header";
@import "partials/header_new";
@import "partials/footer";
@import "partials/sidebar";
@import "partials/grid";
