//catalog gallery grid
@function grid-padding-sm($gutter-size) {
  @return $gutter-size;
}

@function grid-padding-lg($gutter-size) {
  @return calc((2 * #{$gutter-size}) / 1.5);
}

@function base-grid-padding($fn, $gutter-size) {
  @return 0 call(get-function($fn), $gutter-size) calc(4 * #{$gutter-size});
}

@function inner-col-outer-padding($gutter-size) {
  @return calc(#{grid-padding-lg($gutter-size)} / 2);
}

@mixin grid-2-col() {
  > li {
    width: 50%;
  }

  > li:nth-of-type(1n) {
    clear: none;
    padding: base-grid-padding(grid-padding-sm, $gutter-size-sm);

    @include breakpoint(lg-up) {
      padding: base-grid-padding(grid-padding-sm, $gutter-size-lg);
    }
  }

  > li:nth-of-type(2n+1) {
    clear: both;
    padding-left: 0;
  }

  > li:nth-of-type(2n) {
    padding-right: 0;
  }
}

@mixin grid-3-col() {
  > li {
    width: 33.3%;
  }

  > li:nth-of-type(1n) {
    clear: none;
    padding: base-grid-padding(grid-padding-lg, $gutter-size-sm);

    @include breakpoint(lg-up) {
      padding: base-grid-padding(grid-padding-lg, $gutter-size-lg);
    }
  }

  > li:nth-of-type(3n+1) {
    clear: both;
    padding-left: 0;
  }

  > li:nth-of-type(3n) {
    padding-right: 0;
  }

  > li:nth-of-type(3n-1) {
    padding: 0 inner-col-outer-padding($gutter-size-sm);

    @include breakpoint(lg-up) {
      padding: 0 inner-col-outer-padding($gutter-size-lg);
    }
  }
}

@mixin grid-4-col() {
  > li {
    width: 25%;
  }

  > li:nth-of-type(1n) {
    clear: none;
    padding: base-grid-padding(grid-padding-lg, $gutter-size-sm);

    @include breakpoint(lg-up) {
      padding: base-grid-padding(grid-padding-lg, $gutter-size-lg);
    }
  }

  > li:nth-of-type(4n+1) {
    clear: both;
    padding-left: 0;
  }

  > li:nth-of-type(4n) {
    padding-right: 0;
  }

  > li:nth-of-type(4n-1) {
    padding-left: grid-padding-sm($gutter-size-sm);
    padding-right: inner-col-outer-padding($gutter-size-sm);

    @include breakpoint(lg-up) {
      padding-left: grid-padding-sm($gutter-size-lg);
      padding-right: inner-col-outer-padding($gutter-size-lg);
    }
  }
}

@mixin grid-5-col() {
  > li {
    width: 20%;
  }

  > li:nth-of-type(1n) {
    clear: none;
    padding: base-grid-padding(grid-padding-lg, $gutter-size-sm);

    @include breakpoint(lg-up) {
      padding: base-grid-padding(grid-padding-lg, $gutter-size-lg);
    }
  }

  > li:nth-of-type(5n+1) {
    clear: both;
    padding-left: 0;
  }

  > li:nth-of-type(5n) {
    padding-right: 0;
  }

  > li:nth-of-type(5n-1) {
    padding-left: grid-padding-sm($gutter-size-sm);
    padding-right: inner-col-outer-padding($gutter-size-sm);

    @include breakpoint(lg-up) {
      padding-left: grid-padding-sm($gutter-size-lg);
      padding-right: inner-col-outer-padding($gutter-size-lg);
    }
  }

  > li:nth-of-type(5n-2) {
    padding-left: inner-col-outer-padding($gutter-size-sm);
    padding-right: grid-padding-sm($gutter-size-sm);

    @include breakpoint(lg-up) {
      padding-left: inner-col-outer-padding($gutter-size-lg);
      padding-right: grid-padding-sm($gutter-size-lg);
    }
  }
}

@mixin nb-grid($itemsPerRow: 2) {
  display: grid;
  grid-template-columns: repeat($itemsPerRow, 1fr);
  gap: 1rem;
  list-style: none !important;
}

.grid-2 {
  @include nb-grid(2);
}

.grid-3 {
  @include nb-grid(3);
}

.grid-4 {
  @include nb-grid(4);
}

[class*="gallery-grid-"] {
  > li {
    display: block;
    float: left;
    list-style: none;
  }

  &::after {
    clear: both;
    content: " ";
    display: table;
  }
}

.gallery-grid-sm-2 {
  @include grid-2-col;
}

@include breakpoint(md-up) {
  .gallery-grid-md-3 {
    @include grid-3-col;
  }

  .gallery-grid-md-2 {
    @include grid-2-col;
  }
}

@include breakpoint(lg-up) {
  .gallery-grid-lg-2 {
    @include grid-2-col;
  }

  .gallery-grid-lg-3 {
    @include grid-3-col;
  }

  .gallery-grid-lg-4 {
    @include grid-4-col;
  }
}

@include breakpoint(xl-up) {
  .gallery-grid-xl-3 {
    @include grid-3-col;
  }

  .gallery-grid-xl-4 {
    @include grid-4-col;
  }
}

@include breakpoint(xxl-up) {
  .gallery-grid-xxl-5 {
    @include grid-5-col;
  }
}
