$header-top-height: rem-calc(60);
$header-middle-height: rem-calc(65);
$header-bottom-height: rem-calc(36);
$header-bottom-height-small: rem-calc(26);
$header-middle-height-small: rem-calc(50);

$header-height: $header-top-height + $header-middle-height;
$header-height-with-hello-bar: $header-height + $header-bottom-height;

$header-height-small: $header-middle-height-small;
$header-height-with-hello-bar-small: $header-height-small + $header-bottom-height-small;

$header-simple-content-height: rem-calc(50);
$header-height-simple: $header-simple-content-height;

$header-middle-max-width: 1200px;
$header-z-index: 20;

#header-container {
  position: relative;
  max-width: $page-max-width;
  margin: 0 auto;

  &.simple {
    header {
      height: $header-height-simple !important;
    }
    .header-padding {
      height: $header-height-simple !important;
    }
    .header-simple-inner {
      height: $header-simple-content-height;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: solid $primary-color 2px;
      padding: .5rem 1rem;
      box-sizing: border-box;
      .header-logo {
        max-width: 300px;
      }
    }
  }

  &.office-route {
    .header-middle-inner {
      justify-content: center;
      position: relative;
    }
    .header-middle-phone-container {
      position: absolute;
      left: 0;
      height: 100%;
      display: flex;
      align-items: center;
      .header-phone-inner {
        display: flex;
        align-items: center;
        padding-left: 1rem;
        min-width: 200px;
        .callrail-phone {
          color: $white;
          font-size: 1.25rem;
          line-height: 1;
        }
      }
    }
    .header-middle-left {
      width: 100%;
      .header-links-container {
        justify-content: center;
      }
    }
  }

  &.with-hello-bar {
    .header-padding {
      height: $header-height-with-hello-bar;
    }

    header {
      height: $header-height-with-hello-bar;
      .header-bottom {
        display: block;
      }
    }
  }

  .header-padding {
    height: $header-height;
  }

  &.small {
    .header-bottom {
      height: $header-bottom-height-small !important;
    }
    .hello-bar {
      background-color: $white;

      .hello-bar-text {
        color: $primary-color;
        // NOTE: text overflow is NOT working here,
        // I think because div has display: flex
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @include breakpoint(sm) {
          font-size: .75rem;
        }
      }
    }
    .header-padding {
      height: $header-height-small;
    }
    &.with-hello-bar {
      header {
        height: $header-height-with-hello-bar-small !important;
      }
      .header-padding {
        height: $header-height-with-hello-bar-small !important;
      }
    }
    header {
      height: $header-height-small;
      background-color: $primary-color;

      .header-middle {
        display: flex;
        align-items: center;
        padding: .5rem .5rem;
        height: $header-middle-height-small !important;
      }

      .header-left {
        flex: 0 0 5rem;
        display: flex;
        .header-hamburger-button {
          border: none;
          cursor: pointer;
          background-color: inherit;
          display: flex;
          align-items: center;
          .header-hamburger-icon {
            color: $white;
            display: flex;
            align-items: center;
            font-size: 1.75rem;
          }
        }
      }

      .header-center {
        flex: 1;
        display: flex;
        justify-content: center;
        .header-logo-container {
          max-width: 200px;
          .header-logo {
            width: 100%;
          }
        }
        .header-logo-text {
          color: $white;
          line-height: 1;
          letter-spacing: .75rem;
        }
      }

      .header-right {
        flex: 0 0 5rem;
        display: flex;
        justify-content: flex-end;
        padding: 0 .25rem;
      }
    }
  }

  header {
    height: $header-height;
    width: 100%;
    max-width: $page-max-width;
    margin: 0 auto;
    position: fixed;
    top: 0;
    z-index: $header-z-index;
    background-color: white;

    .header-top {
      height: $header-top-height;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-top-left {
        width: 15rem;
      }

      .header-top-center {
        height: 100%;
        display: flex;
        justify-content: center;
        flex: 1;
        .header-logo-container {
          height: 100%;
          padding: 0 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          .header-logo {
            width: 300px;
          }
        }
      }

      .header-top-right {
        height: 100%;
        width: 15rem;
        .header-tabs {
          height: 100%;
          display: flex;
          .header-tab {
            height: 100%;
            padding: 1rem 1.5rem;
            line-height: 1;
            font-weight: bold;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              background-color: $primary-color;
              color: white;
            }
          }
        }
      }
    }

    .header-middle {
      height: $header-middle-height;
      background-color: $primary-color;

      .header-middle-inner {
        height: 100%;
        max-width: $header-middle-max-width;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .header-middle-left {
        align-items: center;
        .header-links-container {
          display: flex;
          @include breakpoint(md-down) {
            display: none;
          }
          .header-links {
            .gifts-link {
              @include breakpoint(lg-down) {
                display: none;
              }
            }

            > li {
              display: inline-block;
              > a {
                @include text-4;
                color: $white;
                padding: 0 .75rem;
                line-height: 1;

                &.get-started {
                  background-color: $white;
                  border-radius: 5rem;
                  padding: .65rem 2rem;
                  margin-left: 1rem;
                  border: none;
                  @include text-4;
                  line-height: 1;
                  color: $primary-color;
                }
              }
            }
            .down-arrow-icon {
              padding-left: .25rem;
            }
          }
        }
      }

      .header-middle-right {
        display: flex;
        align-items: center;
        // NOTE: middle left nav does not have padding,
        // but its li items do have padding. Consider
        // putting padding on parent for consistency
        padding: 0 .75rem;

        .nb-nav-right {
          display: flex;
          align-items: center;
          list-style-type: none;
          padding: 0;
          margin: 0;

          > li {
            @include text-4;
            color: $white;
            margin-right: 1rem;
            &:last-child {
              margin-right: 0 !important;
            }

            &.search-button-container {
              .search-button {
                border: none;
                background-color: inherit;
                padding: 0;
              }
              .search-icon {
                color: $white;
                cursor: pointer;
              }
            }

            &.free-snacks {
              a {
                @include text-4;
                border-radius: 1.5rem;
                background-color: $white;
                color: $primary-color;
                cursor: pointer;
                padding: .75rem 1.75rem;
                line-height: 1;
                display: flex;
                align-items: center;
              }
            }

            &.signin {
              button {
                @include text-4;
                color: $white;
                background-color: inherit;
                border: none;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    .header-bottom {
      display: none;
      height: $header-bottom-height;
    }
  }

  .nb-dropdown {
    position: relative;
    // Make sure that long customer names do not
    // overflow / take up too much space
    &.account-dropdown {
      .nb-dropdown-label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 5rem;
      }
      .nb-dropdown-items {
        width: rem-calc(180);
        left: 20% !important;
        transform: translateX(-50%);
        min-width: 12rem;
      }
      a, button {
        text-align: right !important;
      }
      .nb-dropdown-item-link {
        display: flex !important;
        justify-content: space-between;
        .nb-dropdown-item-left {
          font-weight: bold;
          color: $primary-color;
        }
      }
    }

    .nb-dropdown-label-container {
      padding: 0 .75rem;
      .nb-dropdown-label-link {
        display: flex;
        align-items: center;
      }

      .nb-dropdown-label {
        @include text-4;
        color: $white;
        line-height: 1;
        margin-right: .5rem;
      }

      .nb-dropdown-icon {
        @include svg-icon-carrot-down-white;
      }
    }

    .nb-dropdown-items {
      @include text-4;
      background: $white;
      box-shadow: 0 1px 2px 0 $gray-light;
      border: solid 1px $gray-light;
      left: -16px;
      list-style: none;
      opacity: 0;
      position: absolute;
      text-align: left;
      visibility: hidden;
      width: rem-calc(260);
      z-index: 5;

      li {
        width: 100%;
        color: $black;

        a {
          display: block;
          width: 100%;
          color: $black;
          padding: rem-calc(6) rem-calc(28);
          &.highlighted {
            display: flex;
            justify-content: space-between;
          }
          &:hover {
            background-color: $gray-background;
          }
          .categories-list-item-new {
            color: $primary-color !important;
            font-weight: bold;
          }
        }

        button {
          border: none;
          display: block;
          width: 100%;
          color: $black;
          padding: rem-calc(6) rem-calc(28);
          background-color: inherit;
          text-align: inherit;
          cursor: pointer;
          &:hover {
            background-color: $gray-background;
          }
        }

        &.gifts-link-dropdown {
          a {
            display: flex;
            justify-content: space-between;
            .name-container {
              display: flex;
              align-items: center;
              .gifts-icon-container {
                @include svg-icon-gift;
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }

    .icon-down-arrow {
      transform: translateY(-1px);
    }

    &:focus .nb-dropdown-items, &:hover .nb-dropdown-items {
      opacity: 1;
      visibility: visible;
    }
  }

  .cart-link-holder {
    cursor: pointer;
    .cart-icon {
      @include svg-icon-cart-white;
      position: relative;
      .cart-quantity {
        font-family: $primary-font-medium;
        color: $white !important;
        text-align: center;
        position: absolute;
        top: 15%;
        left: 55%;
        transform: translate(-50%, -50%);
        font-size: .9rem;
      }
    }
  }

  .hello-bar {
    background-color: $accent-color-3;
    color: $white;
    text-align: center;
    width: 100%;
    height: 100%;

    @include hello-bar-small-only {
      .hello-bar-text > span:last-child {
        font-size: .6rem !important;
      }
    }
    .text-4 {
      color: $white;
      height: 100%;
      @include primary-font-bold;
    }
    a {
      color: $white;
      border-bottom: 1px solid $white;
      text-decoration: none;
    }
    .sales-goal {
      display: inline-block;
    }

    .hello-bar-text {
      display: flex;
      align-items: center;
      padding: 2px 5px;
      height: 100%;
      line-height: 1 !important;

      span {
        line-height: 1 !important;
      }
    }

    .hello-bar-link {
      text-decoration: underline;
      height: 100%;
    }

    .hello-bar-text-container {
      display: inline-block;
      height: 100%;
    }
    .checkout-btn {
      background: $white;
      color: $primary-color;
      display: inline-block;
      font-size: rem-calc(14);
      height: 1.5rem;
      margin-left: 0.5rem;
      padding: 0;
      padding-left: 0.5rem;
      width: auto;

      &:focus,
      &:hover {
        color: $primary-color;
      }

      @include breakpoint(md-down) {
        display: none;
      }
    }

    .checkout-btn-arrow {
      @include breakpoint(sm) {
        display: none;
      }

      margin-left: rem-calc(6);

      path {
        fill: $primary-color;
      }
    }

    .hello-bar-gift-icon {
      @include svg-icon-gift-white;
      margin-right: .5rem;
      width: 30px;
    }
  }
}
