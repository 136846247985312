[class*="column"] {
  @include breakpoint(xl-up) {
    &.xlarge-1 {
      @include grid-column(1);
    }
    &.xlarge-2 {
      @include grid-column(2);
    }
    &.xlarge-3 {
      @include grid-column(3);
    }
    &.xlarge-4 {
      @include grid-column(4);
    }
    &.xlarge-5 {
      @include grid-column(5);
    }
    &.xlarge-6 {
      @include grid-column(6);
    }
    &.xlarge-7 {
      @include grid-column(7);
    }
    &.xlarge-8 {
      @include grid-column(8);
    }
    &.xlarge-9 {
      @include grid-column(9);
    }
    &.xlarge-10 {
      @include grid-column(10);
    }
    &.xlarge-11 {
      @include grid-column(11);
    }
    &.xlarge-12 {
      @include grid-column(12);
    }
  }
}
