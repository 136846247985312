@mixin modal-base($fixed-bottom: false, $bottom-size: 0) {
  margin: 0 auto;
  margin-bottom: rem-calc(40);
  z-index: 100;
  text-align: left;
  border: none;
  border-radius: 3px;
  top: 15%;
  right: 0;
  bottom: auto;
  left: 0;
  position: absolute;
  padding: $modal-padding-lg;
  background-color: $white;
  outline: none;
  width: rem-calc(348px);
  max-width: 100%;

  @include breakpoint(sm) {
    @if $fixed-bottom == true {
      min-height: initial;
      height: calc(100% - #{$bottom-size});
      overflow-y: auto;
    }
    @else {
      min-height: 100%;
    }
    width: 100%;
    top: 0;
    padding: 24px $modal-padding-sm;
    margin-bottom: 0;
    border-radius: 0;
  }

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    color: $gray-dark;

    @include breakpoint(sm) {
      top: 10px;
      right: 10px;
    }
  }
}

.learn-more-modal {
  .content {
    padding-bottom: rem-calc(32);
  }

  .cta {
    width: 100%;
  }

  .terms-link {
    @include link-small;
    margin-top: 1rem;
    text-align: center;
  }
}
