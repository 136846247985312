
$row-width: rem-calc(1200);
// $total-columns: 12;
$column-gutter: rem-calc(20);

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

// We use these to define default font stacks
$font-family-sans-serif: Roboto, Arial, sans-serif;
// $font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif;
// $font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;

// We use these to control various global styles
$body-bg: #fff !default;
$body-font-color: #777;
$body-font-family: $font-family-sans-serif;
$body-font-weight: $font-weight-normal !default;
$body-font-style: normal !default;

$base-font-size: 100% !default;

// $base-line-height is 24px while $base-font-size is 16px
$base-line-height: 1.5 !default;

$small-breakpoint: em-calc(440);
$medium-breakpoint: em-calc(640);
$large-breakpoint: em-calc(840);
$xlarge-breakpoint: em-calc(1280);

$small-range: (0, $small-breakpoint) !default;
$medium-range: ($small-breakpoint  + em-calc(1), $medium-breakpoint) !default;
$large-range: ($medium-breakpoint + em-calc(1), $large-breakpoint) !default;
$xlarge-range: ($large-breakpoint  + em-calc(1), $xlarge-breakpoint) !default;
$xxlarge-range: ($xlarge-breakpoint + em-calc(1), em-calc(99999999)) !default;

$text-direction: ltr !default;
$default-float: left !default;
$opposite-direction: right !default;

$screen: "only screen" !default;

$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;

$small-up: $screen !default;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;

$retina: (
  "#{$screen} and (-webkit-min-device-pixel-ratio: 2)",
  "#{$screen} and (min--moz-device-pixel-ratio: 2)",
  "#{$screen} and (-o-min-device-pixel-ratio: 2/1)",
  "#{$screen} and (min-device-pixel-ratio: 2)",
  "#{$screen} and (min-resolution: 192dpi)",
  "#{$screen} and (min-resolution: 2dppx)"
);

$cursor-auto-value: auto !default;
$cursor-pointer-value: pointer !default;

$page-max-width: 1920px;
