@mixin primary-font-medium() {
  font-family: $primary-font-medium;
  font-weight: normal;
  font-style: normal;
}

@mixin primary-font-bold() {
  font-family: $primary-font-bold;
  font-weight: normal;
  font-style: normal;
}

@mixin secondary-font-bold() {
  font-family: $secondary-font-bold;
  font-weight: normal;
  font-style: normal;
}

@mixin h-1() {
  color: $black;
  font-family: $primary-font-medium;
  font-size: rem-calc(36);
  letter-spacing: rem-calc(0.3);
  line-height: rem-calc(40);
  margin-bottom: 1rem;
  font-weight: normal;
  font-style: normal;

  @include breakpoint(md-down) {
    font-size: rem-calc(28);
    line-height: rem-calc(32);
    letter-spacing: rem-calc(0.25);
  }
}

@mixin h-2() {
  color: $black;
  font-family: $primary-font-bold;
  font-size: rem-calc(24);
  letter-spacing: rem-calc(1.5);
  line-height: rem-calc(28);

  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;

  @include breakpoint(md-down) {
    font-size: rem-calc(20);
    line-height: rem-calc(24);
    letter-spacing: rem-calc(1.5);
  }
}

@mixin h-3() {
  color: $black;
  font-family: $primary-font-bold;
  font-size: rem-calc(20);
  letter-spacing: rem-calc(1);
  line-height: rem-calc(24);
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
}

@mixin h-4() {
  color: $black;
  font-family: $secondary-font-bold;
  font-size: rem-calc(16);
  line-height: rem-calc(20);
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal;

  @include breakpoint(md-down) {
    font-size: rem-calc(14);
    line-height: rem-calc(16);
  }
}

@mixin h-5() {
  color: $black;
  font-size: rem-calc(16);
  letter-spacing: rem-calc(0.6);
  line-height: rem-calc(20);
  font-weight: normal;
  font-style: normal;

  @include breakpoint(md-down) {
    font-size: rem-calc(14);
    line-height: rem-calc(18);
  }
}

@mixin h-6() {
  color: $gray-dark;
  font-size: rem-calc(16);
  letter-spacing: rem-calc(0.6);
  line-height: rem-calc(20);
  font-weight: normal;
  font-style: normal;

  @include breakpoint(md-down) {
    font-size: rem-calc(14);
    line-height: rem-calc(18);
  }
}

@mixin h-7() {
  font-size: rem-calc(12);
  color: $gray-dark;
  letter-spacing: rem-calc(0.5);
  line-height: rem-calc(18);
  font-weight: normal;
  font-style: normal;
}

@mixin heading-label() {
  color: $black;
  font-size: rem-calc(16);
  letter-spacing: rem-calc(0.6);
  line-height: rem-calc(22);
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
}

@mixin breadcrumb() {
  color: $gray-dark;
  font-family: $primary-font;
  font-size: rem-calc(12);
  letter-spacing: rem-calc(0.5);
  line-height: rem-calc(14);
  padding: 0;
  font-weight: normal;
  font-style: normal;
}

@mixin breadcrumbs() {
  font-size: rem-calc(12);
  line-height: rem-calc(14);

  li {
    @include breadcrumb;
    display: inline-block;

    a {
      color: inherit;
      line-height: inherit;

      &:hover, &:focus {
        color: $primary-color;
      }
    }

    &:last-child {
      cursor: default;
    }
  }
}

@mixin text-1() {
  color: $black;
  font-family: $primary-font;
  font-size: rem-calc(18);
  line-height: rem-calc(28);
  letter-spacing: rem-calc(0.6);
  font-weight: normal;
  font-style: normal;

  @include breakpoint(md-down) {
    font-size: rem-calc(16);
    line-height: rem-calc(26);
    letter-spacing: rem-calc(0.5);
  }
}

@mixin text-2() {
  color: $black;
  font-family: $primary-font;
  font-size: rem-calc(16);
  line-height: rem-calc(26);
  letter-spacing: rem-calc(0.6);
  font-weight: normal;
  font-style: normal;

  @include breakpoint(md-down) {
    font-size: rem-calc(14);
    line-height: rem-calc(24);
    letter-spacing: rem-calc(0.5);
  }
}

@mixin text-2-bold() {
  @include text-2();
  font-family: $primary-font-bold;
}

@mixin text-3() {
  font-size: rem-calc(16);
  line-height: rem-calc(26);
  letter-spacing: rem-calc(0.6);
  font-family: $primary-font;
  color: $gray-dark;
  font-weight: normal;
  font-style: normal;

  @include breakpoint(md-down) {
    font-size: rem-calc(14);
    line-height: rem-calc(24);
    letter-spacing: rem-calc(0.5);
  }
}

@mixin text-4() {
  font-size: rem-calc(14);
  font-family: $primary-font;
  color: $gray-dark;
  line-height: rem-calc(24);
  letter-spacing: rem-calc(0.5);
  font-weight: normal;
  font-style: normal;
}

@mixin link() {
  color: $primary-color;
  font-family: $primary-font;
  font-size: rem-calc(16);
  line-height: rem-calc(22);
  letter-spacing: rem-calc(0.5);
  font-weight: normal;
  font-style: normal;

  &:hover,
  &:focus {
    cursor: pointer;
  }
}

@mixin link-small() {
  color: $primary-color;
  font-family: $primary-font;
  font-size: rem-calc(14);
  line-height: rem-calc(20);
  letter-spacing: rem-calc(0.5);
  font-weight: normal;
  font-style: normal;
  &:hover,
  &:focus {
    cursor: pointer;
  }
}

@mixin word-wrap() {
  white-space: normal;
  white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  white-space: pre-wrap;       /* css-3 */
  white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin h1-large() {
  color: $black;
  font-family: $primary-font-medium;
  font-size: rem-calc(60);
  letter-spacing: rem-calc(0.4);
  line-height: rem-calc(54);
  font-weight: normal;
  font-style: normal;
  @include breakpoint(md-down) {
    font-size: rem-calc(48);
    line-height: rem-calc(44);
    letter-spacing: rem-calc(0.3);
  }
}
@mixin quote() {
  color: $black;
  font-family: $secondary-font;
  font-size: rem-calc(16);
  letter-spacing: rem-calc(0.3);
  line-height: rem-calc(24);
  font-weight: normal;
  font-style: normal;
}
@mixin quote-large() {
  color: $black;
  font-family: $secondary-font;
  font-size: rem-calc(22);
  letter-spacing: rem-calc(0.3);
  line-height: rem-calc(38);
  font-weight: normal;
  font-style: normal;
}
