@mixin item {
  background-color: inherit;
  border: none;
  padding: .75rem 1rem;
  margin: 0;
  width: 100%;
  text-align: left;
  display: block;
  @include text-4;
  cursor: pointer;

  &:hover {
    background-color: $gray-background;
  }
  > span {
    // Prevent long customer name from overflowing
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 1rem;
  }
}

.sidebar {
  background: white;
  height: 100vh;
  min-width: 250px;
  overflow: auto;
  overflow-x: hidden;
  margin: 0;

  .sidebar-items {
    list-style-type: none;
    > li {

      > a, button, .callrail-phone {
        @include item;

        &.sign-in-button {
          color: $primary-color;
        }
      }

      .option-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $primary-color;

        &.expanded {
          .option-button-icon {
            transform: rotate(180deg);
          }
        }

        .option-button-icon {
          transition: transform .5s ease;
          @include svg-icon-carrot-down;
        }
      }

      .category-items, .account-items {
        > li > a, button {
          @include item;
          padding-left: 2rem;
        }

        li.gifts-link-dropdown {
          a {
            display: flex;
            justify-content: space-between;
            .name-container {
              display: flex;
              align-items: center;
              .gifts-icon-container {
                @include svg-icon-gift;
                margin-right: 1rem;
              }
            }
          }
        }

        a {
          &.highlighted {
            display: flex;
            justify-content: space-between;
          }
          .categories-list-item-new {
            color: $primary-color !important;
            font-weight: bold;
          }
        }
        .mobile-menu-link {
          display: flex !important;
          justify-content: space-between;
          .mobile-menu-link-new {
            font-weight: bold;
            color: $primary-color;
          }
        }
      }
    }
  }

  .sidebar-tabs {
    display: flex;
    .sidebar-tab {
      flex: 1;
      padding: 1rem 1.5rem;
      line-height: 1;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-color;
      color: $white;
      &.active {
        background-color: $white;
        color: $primary-color;
      }
    }
  }

  .chat-link-container {
    padding: 12px 16px;
  }
}
