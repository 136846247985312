.login-modal {

  &.expanded {
    height: 507px;
  }

  @include modal-base();

  &.reset-email-sent, &.magiclink-sent {
    min-height: rem-calc(204);
    width: rem-calc(370);

    @include breakpoint(sm) {
      margin: 25% auto;
    }
  }
}
