.chat-info-modal {

  .live-chat {
    @include link();
    display: inline-block;
    margin-bottom: 20px;

    @include breakpoint(sm) {
      @include cta-secondary-color();
      width: 100%;
    }
  }

  .email-chat {
    @include text-2();
    @include link();

    @include breakpoint(sm) {
      @include cta-secondary-color();
      display: inline-block;
      width: 100%;
      margin-top: 4px;
    }
  }
}
