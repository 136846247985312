//form
$form-row-height: 50px;

// font size
$base-font-size: 1em;

// font family
$primary-font: 'Larsseit', sans-serif;
$primary-font-light: 'Larsseit-Light', sans-serif;
$primary-font-medium: 'Larsseit-Medium', sans-serif;
$primary-font-bold: 'Larsseit-Bold', sans-serif;
$secondary-font: 'FFTisaWeb', sans-serif;
$secondary-font-bold: 'FFTisaWebBold', sans-serif;

//primary colors
$primary-color: #ef5e12; //orange
$cta-color: #ef5e12; //orange
$cta-color-inactive: #f9bfa0; //light orange

//accent colors
$error-color: #be302b; //red
$accent-color-1: #45b49f; //teal
$accent-color-2: #ffbf0f; //yellow
$accent-color-3: #4774b5; //blue
$referral-color: #eae6e5; //temporary color for referral pages--based on current marketing creative.
$referral-blue: #015f82;
$dark-blue: #045d89;

//whites, blacks, grays
$white: #fff;
$black: #231f1a;
$gray-dark: #706c68;
$gray-medium: #b7b4b0;
$gray-light: #e4e0db;
$gray-background: #f5f3f0;

$gray-100: #f5f5f5;
$gray-200: #eee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbf;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;

$shadow-black: rgba(0, 0, 0, 0.3);
$white-translucent: rgba(255, 255, 255, 0.9);

//buttons
$button-background: $white;
$button-border: 1px solid $gray-medium;
$button-border-radius: 3px;
$button-font-family: $primary-font;
$button-font-weight: normal;
$button-height: 3rem;
$button-large-height: 3.5rem;

//content
$content-padding: 0.5rem;
$content-width: 1280px;
$snack-card-border: 1px solid $gray-light;
$snack-card-background: $white;

//new media query breakpoints
$sm: 440px;
$md: 640px;
$lg: 840px;
$xl: 1280px;

// catalog
$catalog-header-height: 94px;

// grid
$gutter-size-sm: 0.5rem;
$gutter-size-lg: 0.75rem;

// forms
$form-input-padding: 0.625rem;

// modals
$modal-padding-sm: 0.625rem;
$modal-padding-lg: 1.5rem;

// tooltips
$tooltip-bg: rgba(36, 35, 52, 0.9);
$tooltip-arrow-width: 10px;
