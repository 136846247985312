@mixin form-placeholder() {
  font-family: $primary-font;
  font-size: rem-calc(16);
  color: $gray-dark;
  letter-spacing: rem-calc(.5);
}

@mixin form-input() {
  background-color: $white;
  border: 1px solid #c0bcbc;
  border-radius: 3px;
  color: $black;
  font-size: rem-calc(16);
  letter-spacing: rem-calc(.5);
  height: 48px;
  padding: 0.25rem $form-input-padding 0 $form-input-padding;

  &::placeholder {
    @include form-placeholder;
  }
}

@mixin form-error() {
  color: $error-color;
  font-family: $primary-font;
  font-size: rem-calc(14);
  line-height: rem-calc(16);
  letter-spacing: rem-calc(0.2);
  font-weight: normal;
  font-style: normal;
}

@mixin form-label() {
  color: $primary-color;
  font-family: $primary-font-bold;
  font-size: rem-calc(10);
  letter-spacing: rem-calc(.5);
  line-height: rem-calc(10);
  text-transform: uppercase;
}

@mixin form-select() {
  background: url("/public/images/svg-icons/carrot-down.svg") no-repeat 90% 50%;
  background-size: 15px;
  height: 49px;
  padding-left: rem-calc(10);
  border: 1px solid $gray-medium;
  border-radius: 3px;
  background-color: $white;
  color: $black;
  font: rem-calc(16) $primary-font;
  letter-spacing: rem-calc(.5);

  // Remove default select styles
  appearance: none;
  &::-ms-expand {
    display: none;
  }
}
