@import "./login_modal";
@import "./chat";

$scrollbar-width: 14px;
$cart-icon-width: rem-calc(55);
$cart-icon-width-md-down: rem-calc(49);
$header-side-padding: rem-calc(15);

$basic-header-height: rem-calc(44);
$corporate-header-height: rem-calc(68);
$header-second-row-height: rem-calc(24);
$header-height: calc(#{$basic-header-height} + #{$header-second-row-height});
$hello-bar-height: rem-calc(36);
$header-border-bottom-height: rem-calc(4);

$header-height-with-hello-bar: calc(#{$header-height} + #{$hello-bar-height});
$basic-header-height-with-hello-bar: calc(#{$basic-header-height} + #{$hello-bar-height});

$corporate-logo-gray: #b0ada5;

@mixin hello-bar-small-only {
  @media (max-width: 520px) { @content; }
}

//-------------------------- START HEADER STYLES --------------------------
#header {
  .header-padding {
    padding-top: calc(#{$header-height} + #{$header-border-bottom-height});
    &.basic {
      padding-top: calc(#{$basic-header-height} + #{$header-border-bottom-height});
    }
    &.unstick-mobile {
      @include breakpoint(sm) {
        display: none;
      }
    }
    &.with-hello-bar {
      padding-top: $header-height-with-hello-bar;
      @include breakpoint(md-down) {
        padding-top: $basic-header-height-with-hello-bar;
      }
    }
    &.basic.with-hello-bar {
      padding-top: $basic-header-height-with-hello-bar;
    }
    @include breakpoint(md-down) {
      padding-top: $basic-header-height;
    }
  }

  .site-header {
    @include fixed-position;
    top: 0;
    right: 0;
    left: 0;
    z-index: 20;
    background: $white;
    border-bottom: $header-border-bottom-height solid $primary-color;
    @include breakpoint(md-down) {
      height: auto;
    }

    &.unstick-mobile {
      @include breakpoint(sm) {
        position: relative;
      }
    }

    &.with-hello-bar {
      border-bottom: 0;
    }
    .logo {
      display: inline-block;
      width: 185px;
      @include breakpoint(md-down) {
        float: left;
        margin-left: 1rem;
        width: 150px;
      }
    }
    &.basic {
      .logo {
        @include breakpoint(md-down) {
          float: none;
          margin-left: 0;
          width: 170px;
        }
      }
      .nb-nav.nb-nav-right {
        right: 0;
      }
    }

    .nb-nav {
      text-transform: uppercase;
      display: inline-block;
      > li {
        @include h-7;
        display: inline-block;
        padding: 0 rem-calc(6);

        @include breakpoint(lg-down) {
          padding: 0 rem-calc(3);
        }
      }
      a {
        color: $gray-dark;
        &:hover, &:active {
          color: $primary-color;
        }
      }
      .free-snacks a {
        color: $primary-color;
      }
      &.nb-nav-left {
        position: absolute;
        left: 0;

        .free-snacks {
          display: none;

          @include breakpoint(lg) {
            display: inline-block;
          }
        }

        .aboutus-left {
          display: none;
        }
      }
      &.nb-nav-right {
        position: absolute;
        margin-right: 0.25rem;
        right: $cart-icon-width;

        @include breakpoint(md-down) {
          right: $cart-icon-width-md-down;
        }
        .account, .signin {
          @include breakpoint(md-down) {
            display: none;
          }
        }
        #account-dropdown {
          z-index: 20;
          background: $white;
          box-shadow: 0 1px 2px 0 $gray-light;
          border: solid 1px $gray-light;
          display: none;
          list-style: none;
          padding-top: 3px;
          padding-bottom: 8px;
          position: absolute;
          right: rem-calc(-14);
          top: 2.5rem;
          text-align: right;
          width: rem-calc(172);
          li {
            padding: rem-calc(10) rem-calc(30) rem-calc(10) rem-calc(20);
            a {
              @include text-4;
              text-transform: none;
              &.sub-link {
                color: $primary-color;
              }
              &:focus,
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
        .account {
          padding-bottom: 0.5rem;
          &:hover,
          &:focus {
            #account-dropdown {
              display: block;
            }
          }
        }
        .free-snacks {
          @include breakpoint(lg-down) {
            display: none;
          }
        }
      }
      .student-discount-left,
      .aboutus-left,
      .corporate-left {
        @include breakpoint(lg-down) {
          display: none;
        }
      }
    }

    .header-content {
      max-width: $content-width;
      background: $white;
      margin: 0 auto;
      padding: 0 $header-side-padding;
      position: relative;
      text-align: center;
      @include breakpoint(md-down) {
        padding: 0;
      }
      .header-rows {
        position: relative;
        .row:first-child {
          height: $basic-header-height;
          line-height: $basic-header-height;
        }
        .row:nth-child(2) {
          height: $header-second-row-height;
          line-height: $header-second-row-height;
        }
        .header-links-container {
          @include breakpoint(md-down) {
            display: none;
          }
          .header-links {
            a {
              color: $black;
              &:focus,
              &:hover,
              &:active {
                color: $primary-color;
              }
            }
            .gifts-link {
              @include breakpoint(lg-down) {
                display: none;
              }
            }
            > li {
              @include text-4;
              color: $black;
              display: inline-block;
              padding: 0 rem-calc(12);
            }
            .down-arrow-icon {
              padding-left: 4px;
            }
          }
        }
      }

      .header-cart-icon {
        position: absolute;
        right: $header-side-padding;
        z-index: 1;
        @include breakpoint(md-down) {
          right: 0;
        }
        .cart-link-holder {
          display: inline-block;
          line-height: 52px;
          cursor: pointer;
          height: $header-height;
          width: $cart-icon-width;
          background-color: $gray-background;
          @include breakpoint(md-down) {
            height: $basic-header-height;
            width: $cart-icon-width-md-down;
          }
          .cart-icon {
            @include svg-icon-cart;
            margin: 8px auto;
            position: relative;
            top: 16px;
            text-align: center;
            @include breakpoint(md-down) {
              margin: 2px auto;
              top: 12px;
            }
            span {
              font-family: $primary-font-medium;
              color: $black;
              position: relative;
              bottom: 21px;
              left: 2px;
              font-size: rem-calc(16);
              padding-right: 0;
              padding-left: 0;
              @include breakpoint(md-down) {
                bottom: 20px;
              }
            }
          }
        }
      }
    }
  }

  .nb-dropdown {
    position: relative;

    .nb-dropdown-items {
      @include text-4;
      background: $white;
      box-shadow: 0 1px 2px 0 $gray-light;
      border: solid 1px $gray-light;
      left: -16px;
      list-style: none;
      opacity: 0;
      position: absolute;
      text-align: left;
      visibility: hidden;
      width: rem-calc(260);
      z-index: 5;

      li {
        line-height: 1.5rem;
        padding: rem-calc(6) rem-calc(28);
        width: 100%;

        a {
          width: 100%;
          &.highlighted {
            color: $primary-color !important;
          }
        }

        &.gifts-link-dropdown {
          border-top: 1px solid $gray-light;
          a {
            &::before {
              content: ' ';
              display: inline-block;
              @include svg-icon-gift;
              position: relative;
              top: 3px;
              left: rem-calc(-12);
            }
          }
        }
      }
    }

    .icon-down-arrow {
      transform: translateY(-1px);
    }

    &:focus .nb-dropdown-items, &:hover .nb-dropdown-items {
      opacity: 1;
      visibility: visible;
    }
  }
  //-------------------------- START OFFICE PAGE --------------------------
  #office-header {
    .header-content {
      text-align: inherit;
    }
    .header-row {
      @include breakpoint(lg-up) {
        height: $corporate-header-height;
        line-height: $corporate-header-height;
      }
    }

    .corp-logo-text {
      text-transform: uppercase;
      float: right;
      margin-top: -32px;
      font-size: 10px;
      letter-spacing: 2px;
      font-weight: bold;
      color: $corporate-logo-gray;
      @include breakpoint(md-down) {
        font-size: 7px;
        margin-top: -21px;
        margin-bottom: -4px;
      }
    }

    .logo {
      width: 206px;
      @include breakpoint(md-down) {
        width: 156px;
      }
      img {
        height: 17px;
        @include breakpoint(md-down) {
          height: 13px;
        }
        vertical-align: inherit;
      }
    }

    .office-nav {
      display: inline-block;
      a {
        color: $black;
      }

      li {
        display: inline-block;
        list-style: none;

        @include breakpoint(md-down) {
          display: none;
        }

        @include breakpoint(lg-up) {
          margin-left: 20px;
        }

        @include breakpoint(xxl-up) {
          margin-left: 30px;
        }
      }
    }

    .header-buttons {
      display: inline;
      float: right;
      height: 42px;

      @include breakpoint(md-down) {
        padding-right: 8px;
      }

      .header-button {
        margin-left: 10px;
        display: inline-block;
        width: 162px;
        height: 42px;
        @include breakpoint(lg-down) {
          width: 90px;
        }
        @include breakpoint(md-down) {
          font-size: rem-calc(12);
          height: rem-calc(12);
          line-height: 1px;
          min-width: 60px;
          width: 60px;
        }

        &:hover, &:focus {
          color: $white;
        }
      }
    }
  }
}
//-------------------------- END HEADER STYLES --------------------------

//-------------------------- START STICKY WRAPPER STYLES --------------------------
.sticky-wrapper {
  &.sticky {
    @include fixed-position;
    margin: 0 0.5rem;
    top: calc(#{$header-height} + #{$header-border-bottom-height});
    @include breakpoint(md-down) {
      top: calc(#{$basic-header-height} + #{$header-border-bottom-height});
      border-radius: 3px;
      &.catalog-header {
        padding-top: 10px;
        padding-bottom: 10px;
        .filter-button-container-mobile {
          width: 80%;
          margin: 0 auto;
        }
      }
    }
    left: 0;
    right: 0;
    z-index: 100;
    &.hello-bar-adjustment {
      top: $header-height-with-hello-bar;
      @include breakpoint(md-down) {
        top: $basic-header-height-with-hello-bar;
      }
    }
  }
}

.nb-tooltip.mobile-menu-tooltip {
  max-width: 240px;
  z-index: 21;
  @include breakpoint(lg-up) {
    display: none;
  }
  p {
    margin: 0 0 1rem;
  }
  .nb-tooltip-arrow {
    left: 10%;
  }
}

.nb-tooltip.account-dropdown-tooltip {
  max-width: 350px;
  @include breakpoint(md-down) {
    display: none;
  }
  .nb-tooltip-arrow {
    /**
     * 28px is how much we pushed down the tooltip in the tooltip config.
     * This calculation keeps the arrow pointing directly at the target
     */
    top: calc(50% - 28px);
  }
  p {
    margin: 0 0 1rem;
  }
}

.nb-tooltip.cart-tooltip {
  max-width: 350px;
  .nb-tooltip-arrow {
    right: 6%;
  }
  p {
    margin: 0 0 1rem;
  }
}

//mobile menu icon
@include breakpoint(md-down) {
  .sidebar-menu {
    border-right: 1px solid $gray-light;
    width: rem-calc(45);
    float: left;
  }
  .sidebar-menu-icon {
    @include svg-icon-hamburger;
    display: inline-block;
    vertical-align: middle;
  }
}

.hello-bar {
  background-color: $primary-color;
  color: $white;
  text-align: center;
  width: 100%;
  z-index: 3;
  height: $hello-bar-height;

  @include hello-bar-small-only {
    .view-cart > span:last-child {
      font-size: .6rem !important;
    }
  }
  .text-4 {
    color: $white;
    height: 100%;
    @include primary-font-bold;
  }
  a {
    color: $white;
    border-bottom: 1px solid $white;
    text-decoration: none;
  }
  .sales-goal {
    display: inline-block;
  }

  .view-cart {
    display: flex;
    align-items: center;
    padding: 2px 5px;
    height: 100%;
    text-decoration: underline;
    line-height: 1 !important;

    span {
      line-height: 1 !important;
    }
  }

  .hello-bar-link {
    text-decoration: underline;
    height: 100%;
  }

  .hello-bar-text {
    display: inline-block;
    height: 100%;
  }
  .checkout-btn {
    background: $white;
    color: $primary-color;
    display: inline-block;
    font-size: rem-calc(14);
    height: 1.5rem;
    margin-left: 0.5rem;
    padding: 0;
    padding-left: 0.5rem;
    width: auto;

    &:focus,
    &:hover {
      color: $primary-color;
    }

    @include breakpoint(md-down) {
      display: none;
    }
  }

  .checkout-btn-arrow {
    @include breakpoint(sm) {
      display: none;
    }

    margin-left: rem-calc(6);

    path {
      fill: $primary-color;
    }
  }

  .hello-bar-gift-icon {
    @include svg-icon-gift-white;
    margin-right: .5rem;
    width: 30px;
  }
}

.corporate-button {
  font-family: $primary-font;
  cursor: pointer;
  line-height: 1;
  text-transform: none !important;

  &.loud {
    background-color: $primary-color !important;
    padding: .5rem 1rem;
    text-align: center;
    color: white !important;
    border-radius: 60px;
  }
}
