$footer-heigth: 300px;
$footer-height-mobile: 160px;

.error-page {
  text-align: center;
  position: relative;
  height: 100%;

  .header {
    @include h-1;

    @include breakpoint(md-down) {
      @include h-3;
    }
  }

  .error-footer-banner {
    background-image: url("/public/images/error/error_banner.jpg");
    background-repeat: repeat;
    display: inline-block;
    width: 100%;
    height: $footer-heigth;
    margin-top: -$footer-heigth;
    background-size: cover;
    background-position: top center;
    position: relative;
    clear: both;

    @include breakpoint(md-down) {
      background-image: url("/public/images/error/error_banner_mobile.jpg");
      height: $footer-height-mobile;
      margin-top: -$footer-height-mobile;
    }
  }

  .error-text {
    padding: 25px 10px;
  }

  .error-content {
    padding-bottom: $footer-heigth;
    min-height: 100%;
    overflow: auto;

    @include breakpoint(md-down) {
      padding-bottom: $footer-height-mobile;
    }
  }
}

.error-404 {
  .error-header-banner {
    background-image: url("/public/images/error/404.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 215px;
    background-position: top center;

    @include breakpoint(md-down) {
      background-image: url("/public/images/error/404_mobile.jpg");
      height: 135px;
    }
  }
}

.error-500 {
  .error-header-banner {
    background-image: url("/public/images/error/500.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 257px;
    background-position: top center;

    @include breakpoint(md-down) {
      background-image: url("/public/images/error/500_mobile.jpg");
      height: 163px;
    }
  }
}
