@mixin cta() {
  background-color: $cta-color;
  border: none;
  border-radius: rem-calc(3px);
  color: $white;
  font-family: $primary-font;
  font-size: rem-calc(16);
  height: $button-height;
  line-height: 1;
  min-width: rem-calc(100px);
  padding: calc((3rem - 1rem) / 2) 0;
  text-align: center;

  &:focus,
  &:hover {
    outline: none;
    background-color: $cta-color;
  }

  a {
    color: $white;
  }
}

@mixin cta-inactive() {
  background-color: $cta-color-inactive;
}

@mixin cta-large() {
  @include cta;
  height: $button-large-height;
  min-width: rem-calc(300px);
  padding: calc((3rem - 1.125em) / 2) 0;
}

@mixin cta-secondary($cta-text-color: $black, $cta-border-color: $gray-medium) {
  @include cta;
  background-color: $white;
  border: 1px solid $cta-border-color;
  color: $cta-text-color;

  &:focus,
  &:hover {
    background-color: $white;
    color: $cta-text-color;
    outline: none;
  }

  a {
    color: $cta-text-color;
  }
}

@mixin cta-secondary-color() {
  @include cta-secondary($cta-color, $cta-color);
}

@mixin orange-plus-button() {
  width: 15px;
  height: 15px;
  display: inline-block;
  @include svg-icon-bg('plus-orange');
  background-size: 15px;
  background-position: center;
}
